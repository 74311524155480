import React from "react";

import { ReactComponent as InnovacerLogo } from "../assets/company_logos/innovaccer.svg";
import { ReactComponent as VideaHealthLogo } from "../assets/company_logos/videa.svg";

const PortfolioEntries = [
  {
    name: "Innovaccer",
    url: new URL("https://innovaccer.com/"),
    logo: <InnovacerLogo />,
  },
  {
    name: "Videa AI",
    url: new URL("https://www.videa.ai/"),
    logo: <VideaHealthLogo />,
  },
];

const PortfolioCard = ({ name, url, logo }) => {
  // flex flex-col
  return (
    <a
      target="_blank"
      href={url}
      className="p-7 flex flex-col content-center justify-center items-center hover:bg-[#0071FE] hover:text-[#FFFFFF] w-[250px] cursor-pointer rounded-xl transition-all duration-300 ease-in-out"
    >
      <div className="h-[250px] flex content-center justify-center items-center">
        {logo}
      </div>
      <p className="uppercase">
        <strong>{name}</strong>
      </p>
    </a>
  );
};

export default function Portfolio() {
  return (
    <div className="p-4 lg:p-16 relative overflow-x-clip min-h-[1000px]">
      <div className="flex pt-36 mb-4" id="Portfolio">
        <div
          style={{ fontFamily: "SharpSans-Medium" }}
          className="tracking-widest"
        >
          PORTFOLIO
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 content-center h-auto pl-3 lg:pl-10 ">
          <div className="border-b-2 w-28 lg:w-72 h-[50%] border-[#F15A22]"></div>
        </div>
      </div>
      <div className="p-4 m-auto flex flex-wrap items-center content-center gap-8 justify-center sm:justify-start">
        {PortfolioEntries.map((props) => (
          <PortfolioCard {...props} />
        ))}
      </div>
      <img
        src={require("../assets/bg_pixels_left.png")}
        className="top-[-80px] left-[0px] pixels"
      />
    </div>
  );
}
