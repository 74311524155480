import "./fonts/woff/SharpSansforBAM-Bold.woff";
import "./fonts/woff/SharpSansforBAM-SemiBold.woff";
import "./fonts/woff/SharpSansforBAM-Medium.woff";
import "./fonts/space_grotesk/SpaceGrotesk-Regular.ttf";
import "./fonts/space_grotesk/SpaceGrotesk-Light.ttf";
import "./fonts/space_grotesk/SpaceGrotesk-Medium.ttf";
import "./App.css";

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { getCookieConsentValue } from "react-cookie-consent";

import Home from "./pages/Home";
import FraudulentSchemesWarning from "./pages/FraudulentSchemesWarning";
import BlogIndex from "./pages/blogs/BlogIndex";
import BlogPost from "./pages/blogs/BlogPost";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAgreement from "./pages/UserAgreement";
import CookieConsentBanner from "./Components/CookieConsentBanner";
import ScrollToHash from "./Components/ScrollToHash";

const CONSENT_COOKIE_NAME = "consent_cookie";
const GOOGLE_TAG_ID = "G-T4C9DWXHC9";

function App() {
  const [userConsented, setUserConsented] = useState(
    getCookieConsentValue(CONSENT_COOKIE_NAME) === "true",
  );

  return (
    <Router>
      <Helmet>
        {userConsented && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG_ID}`}
          ></script>
        )}
        {userConsented && (
          <script integrity="sha256-dMOV9RBB4/XOQZcpTnZnRiTZbyU6XwMyWWMEsKLtWrE=">{`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GOOGLE_TAG_ID}');`}</script>
        )}
      </Helmet>
      <ScrollToHash />
      <CookieConsentBanner
        cookieName={CONSENT_COOKIE_NAME}
        visible={!userConsented}
        onAccept={() => setUserConsented(true)}
        onDecline={() => setUserConsented(false)}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="user-agreement" element={<UserAgreement />} />
        <Route
          path="fraudulent-schemes-warning"
          element={<FraudulentSchemesWarning />}
        />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="blog">
          <Route index element={<BlogIndex />} />
          <Route path=":slug" element={<BlogPost />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
