import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const waitForImages = () => {
    const images = [
        ...document.querySelectorAll("img")
    ]

    return Promise.all(
        images.map(
            (image) => new Promise(
                (result) => {
                    image.onload = () => result(
                        [image.width, image.height]
                    )

                    image.onerror = () => result(null);
                }
            )
        )
    );
}

export default function ScrollToHash() {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (!hash) return;

        const element = document.querySelector(hash);

        if (!element) return;

        /* We don't use fixed-size image heights, so navigating to the 
         * anchor before all images are loaded causes us to fall short of the 
         * intended position if there are images above the anchor. We try
         * to mitigate this by waiting for all images to load (or fail to load)
         * before scrolling the target element into view.
         */

        waitForImages().then(
            () => element.scrollIntoView({ behavior: "smooth" })
        );
    }, [location?.hash]);

    return null;
}
